/* MY PAGE SECTION */
.my-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #283152;
  color: white;
}

.my-page-container .sub-container {
  width: 800px;
  border: 2px solid white;
  padding: 50px;
  border-radius: 5px;
  background-color: #ffffff15;
}

.sub-container .top {
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
}

.sub-container .top .icon {
  width: 140px;
  height: 140px;
  /* background-color: white; */
  display: inline-block;
  border-radius: 100px;
  /* margin-right: 10px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.sub-container .bottom {
  /* text-align: center; */
  display: flex;
  font-weight: bold;
  margin-bottom: 30px;
}

.sub-container .profile-img {
  /* text-align: center; */
  width: 150px;
  height: 150px;
  border-radius: 150px;
}

.sub-container .bottom .left {
  width: 50%;
}

.sub-container .bottom .content {
  display: flex;
  justify-content: center;
  /* margin-bottom: 40px; */
  height: 60px;
  align-items: center;
}

.sub-container .bottom .right {
  width: 50%;
}

.sub-container .bottom .title {
  width: 170px;
  text-align: left;
  color: rgb(228, 228, 50);
}

.sub-container .bottom .detail {
  width: 300px;
  text-align: left;
}

.sub-container .bottom .detail.edit .input-edit {
  border-radius: 5px;
  padding: 10px 25px;
  background-color: #1b2649;
  border: 1px solid white;
  color: rgb(228, 228, 50);
}

.sub-container .bottom .detail.edit .input-edit::placeholder {
  color: rgb(189, 189, 42);
}

.btn-container {
  display: flex;
  justify-content: space-between;
}

.btn-container > button {
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #1b2649;
  color: white;
  font-weight: bold;
}

.btn-container .btn-edit {
  background-color: gray;
}

.btn-container .btn-drop-out {
  background-color: rgb(112, 23, 23);
}
