/* User Detail Modal Section */

.facility-detail-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.facility-detail-modal-container .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.facility-detail-modal-container .header-container .icon {
    margin-bottom: 20px;
    width: 92px;
    height: 92px;
}

.facility-detail-modal-container .header-container .name {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 3px;
}

.facility-detail-modal-container .header-container .sub-header-container {
    display: flex;
    align-items: center;
}

.facility-detail-modal-container .header-container .sub-header-container .facility-type {
    font-size: 17px;
    margin-right: 6px;
    font-weight: 400;
}

.facility-detail-modal-container .header-container .sub-header-container .facility-id {
    font-size: 14px;
    color: #8f8f8f;
}

.facility-detail-modal-container .detail-container {
    margin-bottom: 25px;
    width: 90%;
}

.facility-detail-modal-container .detail-container .each-detail {
    display: flex;
    font-size: 16px;
    padding: 0 10px;
}

.facility-detail-modal-container .detail-container .each-detail .title {
    width: 100px;
    text-align: left;
}
