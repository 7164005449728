.icon-img {
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
}

.icon-img.filter {
    width: 45px;
    height: 45px;
    filter: grayscale(100%);
}

.checkbox-modal-container {
    width: 100px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: white;
    z-index: 10;
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
}
