.modal-sms-title {
  font-size: 20px;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.sms-edit-modal {
  margin-top: 40px;
}

.sms-row-container {
  width: 100%;
}

.sms-send-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sms-row-title {
  width: 150px;
}

.sms-row-input {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.contents {
  height: 100px;
  white-space: pre-wrap;
}

.sms-number-select {
  width: 100%;
}

.sms-complete-detail {
  background-color: lightgray;
  padding: 20px;
  border-radius: 5px;
  color: gray;
  margin-bottom: 20px;
}

.sms-complete-each-detail {
  display: flex;
  margin: 10px 0px;
}

.sms-complete-each-detail .title {
  width: 70px;
  text-align: left;
}

.sms-Complete-header {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}
