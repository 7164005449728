/* SMS 전송 Section */
.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #DDDDDD;
}

.title-container .right {
    display: flex;
    align-items: center;
}

.title-container .modal-sms-title {
    font-size: 22px;
    font-weight: bold;
}

.title-container .btn-close {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.sms-send-container {
    width: 100%;
}

.sms-send-container .each-row {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
    width: 100%;
}

.sms-send-container .each-row .title {
    width: 110px;
    font-size: 16px;
}

.sms-send-container .each-row .detail {
    line-height: 28px;
}

.sms-send-container .each-row .detail .main {
    font-size: 18px;
    font-weight: bold;
}

.sms-send-container .each-row .detail .sub {
    font-size: 14px;
    color: #666666;
}

.sms-send-container .each-row .detail .sub.wrapper {
    display: flex;
}

.sms-send-container .each-row .detail .sub.wrapper .left {
    margin-right: 20px;
}

.sms-send-container .select {
    width: 100%;
    font-size: 14px;
}

.sms-send-container .each-row .input {
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 80%;
    padding: 11px;
}

.sms-send-container .each-row .input.textarea {
    min-height: 200px;
    max-height: 200px;
    resize: none;
}

.sms-send-container .btn-conatiner-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.sms-send-container .btn-container {
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
}

.sms-send-container .btn-container .btn {
    width: 150px;
    height: 45px;
    font-size: 16px;
    background-color: var(--main-ny-sub-black);
}

.sms-send-container .btn-container .btn-save {
    margin-right: 10px;
    background-color: white;
    color: var(--main-ny-sub-black);
    border: 1px solid var(--main-ny-sub-black);
}

/* 전송 목록 Section */

.sms-list-container {
    width: 100%;
    height: 100%;
}

.sms-list-container .sms-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-bottom: 10px;
}

.sms-list-container .sms-list-header .title {
    color: #1b2649;
    font-weight: bold;
}

.sms-list-container .sms-list-header .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 360px;
}

.sms-list-container .sms-list-header .search-container .select {
    width: 200px;
    font-size: 13px;
    border-radius: 5px;
    z-index: 3;
}

.sms-list-container .sms-list-header .search-container .search {
    padding: 10.5px 10px;
    border-radius: 5px;
    color: black;
    border: 1px solid rgb(196, 196, 196);
    width: 100%;
}

.sms-list-container .table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.sms-list-container .table-container .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.sms-list-container .table-container .btn-del {
    background-color: white;
    border: 1px solid var(--main-ny-sub-black);
    color: var(--main-ny-sub-black);
    width: 134px;
    height: 45px;
    border-radius: 5px;
}

.sms-list-container .table-container .btn-send {
    background-color: var(--main-ny-sub-black);
    color: white;
    width: 134px;
    height: 45px;
    border-radius: 5px;
}


/* 임시보관함 Section */

.sms-temp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sms-temp-container .sms-temp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-bottom: 10px;
    width: 100%;
}

.sms-temp-container .sms-temp-header .title {
    color: #1b2649;
    font-weight: bold;
}

.sms-temp-container .sms-temp-header .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 460px;
}

.sms-temp-container .sms-temp-header .search-container .select {
    width: 200px;
    font-size: 13px;
    border-radius: 5px;
    z-index: 3;
}

.sms-temp-container .sms-temp-header .search-container .search {
    padding: 10.5px 10px;
    border-radius: 5px;
    color: black;
    border: 1px solid rgb(196, 196, 196);
    width: 250px;
}

.sms-temp-container .table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.sms-temp-container .btn-container {
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.sms-temp-container .btn-container .btn {
    width: 134px;
    font-size: 16px;
}

.sms-temp-container .table-container .btn-container .btn-del {
    background-color: white;
    color: var(--main-ny-sub-black);
    border: 1px solid var(--main-ny-sub-black);
    font-weight: bold;
}

.sms-temp-container .table-container .btn-container .btn-send {
    background-color: var(--main-ny-sub-black);
    color: white;
    border: 1px solid var(--main-ny-sub-black);
    font-weight: bold;
}
