/* KAKAO BUTTON  */
.kakao-btn {
  background-color: yellow !important;
  height: 50px;
  margin: 5px 5px !important;
  justify-content: flex-start !important;
}

.kakao-btn:hover {
  background-color: rgb(236, 236, 1) !important;
}

.kakao-btn svg {
  font-size: 20px;
  margin-right: 10px;
}

.kakao-btn span {
  text-transform: none !important;
  font-size: 20px;
}

/* NAVER BUTTON  */

.naver-btn {
  background-color: #3fce60 !important;
  height: 50px;
  margin: 5px 5px !important;
  justify-content: flex-start !important;
  color: white !important;
}

.naver-btn:hover {
  background-color: #35af51 !important;
}

.naver-btn span {
  text-transform: none !important;
  font-size: 20px;
}

.naver-btn span .naver-icon {
  text-transform: none !important;
  font-size: 18px;
  font-size: 25px;
  margin-right: 10px;
  font-weight: bold;
}

/* Google BUTTON  */
.google-btn {
  background-color: #ffffff !important;
  height: 50px;
  margin: 5px 5px !important;
  justify-content: flex-start !important;
}

.google-btn:hover {
  background-color: rgb(236, 236, 236) !important;
}

.google-btn svg {
  font-size: 20px;
  margin-right: 10px;
}

.google-btn span {
  text-transform: none !important;
  font-size: 20px;
}

/* Google BUTTON  */
.facebook-btn {
  background-color: rgb(59, 89, 152) !important;
  height: 50px;
  margin: 5px 5px !important;
  justify-content: flex-start !important;
}

.facebook-btn:hover {
  background-color: rgb(53, 75, 124) !important;
}

.facebook-btn svg {
  font-size: 20px;
  margin-right: 10px;
}

.facebook-btn span {
  text-transform: none !important;
  font-size: 20px;
  color: white;
}

/* Apple BUTTON  */
.apple-btn {
  background-color: #ffffff !important;
  height: 50px;
  margin: 5px 5px !important;
  justify-content: flex-start !important;
}

.apple-btn:hover {
  background-color: rgb(236, 236, 236) !important;
}

.apple-btn svg {
  font-size: 20px;
  margin-right: 10px;
}

.apple-btn span {
  text-transform: none !important;
  font-size: 20px;
}

@media screen and (max-width: 576px) {
  .social-label {
    display: none;
  }
  .social-btn {
    min-width: auto !important;
    width: 50px !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
  }

  .social-btn .MuiButton-label,
  .social-btn .MuiButton-label .naver-icon {
    display: inline-block;
    margin: 0;
  }
  .social-btn .MuiButton-label svg {
    margin: 0;
  }
}
