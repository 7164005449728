.detail_list {
    width: 60%;
    overflow: auto;
    height: 300px;
    overflow-x: hidden;
}

.item_container {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 6px;
}

.title {
    width: 110px;
}

.detail {
    width: 360px;
}
