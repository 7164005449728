.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    min-height: auto;
    background-color: white;
    border-radius: 15px;
    transition: width 0.8s, height 0.8s;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px;
}

.title {
    font-size: 22px;
    font-weight: bold;
}

.closeBtn {
    cursor: pointer;
}

.contentsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 20px;
}

.button {
    width: 45%;
    height: 45px;
    border-radius: 5px;
    font-weight: bold;
}

.leftBtn {
    color: var(--main-ny-sub-black) !important;
    border-color: var(--main-ny-sub-black) !important;
}

.rightBtn {
    color: white !important;
    background-color: var(--main-ny-sub-black) !important;
}
