.button {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    color: #496ded;
    font-size: 16px;
    font-weight: bold;
    transition: transform 0.2s ease-in-out;
    border: 1px solid #fff;
}

.button:hover {
    border-color: #496DED;
    transform: scale(1.1);
}
