.event-edit-container {
    width: 100%;
    height: 150%;
    padding: 20px 40px;
}

.event-edit-container .each-row {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    width: 100%;
}

.event-edit-container .each-row .title {
    width: 110px;
    font-size: 16px;
    text-align: left;
}

.event-edit-container .each-row .detail {
    width: 100%;
}


