/* User Detail Modal Section */

.user-detail-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-detail-modal-container .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-detail-modal-container .header-container .icon {
    margin-bottom: 16px;
    margin-top: 20px;
}

.user-detail-modal-container .header-container .name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
}

.user-detail-modal-container .header-container .department {
    font-size: 17px;
}

.user-detail-modal-container .detail-container {
    padding: 30px 30px;
    width: 100%;
}

.user-detail-modal-container .detail-container .each-detail {
    display: flex;
    font-size: 16px;
    padding: 0 10px;
}

.user-detail-modal-container .detail-container .each-detail .title {
    width: 120px;
    text-align: left;
}

.user-detail-modal-container .detail-container .each-detail .detail {
    width: calc(100% - 120px);
    text-align: left;
}

.user-detail-modal-container .detail-container .each-detail .select {
    font-size: 14px;
    width: 400px;
}

.user-detail-modal-container .detail-container .title.edit {
    width: 150px;
    color: #00034f;
    font-weight: bold;
}

.user-detail-modal-container .btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.user-detail-modal-container .btn-container > button {
    width: 180px;
    height: 45px;
    font-size: 16px;
}

.user-detail-modal-container .btn-container .btn-close {
    background-color: white;
    color: var(--main-ny-sub-black);
    border: 1px solid var(--main-ny-sub-black);;
}

.user-detail-modal-container .btn-container .btn-edit {
    background-color: var(--main-ny-sub-black);
    margin-left: 10px;
}
