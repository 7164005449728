.modal-delete-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  justify-content: center;
  height: 100%;
}

.modal-delete-container > h2 {
  font-weight: bold;
  font-size: 25px;
  margin: 20px 0px;
}

.modal-delete-container .detail {
  margin-bottom: 20px;
  line-height: 25px;
  font-size: 17px;
}

.modal-delete-container .btn-conatiner {
  display: flex;
  justify-content: space-evenly;
}

.modal-delete-container .btn-conatiner > button {
  width: 45%;
  height: 45px;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.modal-delete-container .btn-conatiner .btn-cancle {
  background-color: white;
  border: 1px solid#1b2649;
  color: #1b2649;
}

.modal-delete-container .btn-conatiner .btn-ok {
  background-color: #1b2649;
  color: white;
}
