.modal-sms-title {
  font-size: 20px;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.sms-edit-container {
  width: 100%;
  margin-top: 40px;
}

.sms-edit-container .each-row {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  width: 100%;
}

/* .select .css-319lph-ValueContainer {
  padding: 0px !important;
} */

.sms-edit-container .each-row .title {
  width: 120px;
}

.sms-edit-container .each-row .select {
  border-radius: 5px;
  padding: 0px;
  width: 83%;
}

.sms-edit-container .each-row .input {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 80%;
  padding: 11px;
}

.sms-edit-container .each-row .input.textarea {
  height: 100px;
}

.sms-edit-container .btn-conatiner-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.sms-edit-container .btn-conatiner-wrapper .btn-container {
  display: flex;
  width: 220px;
  justify-content: space-between;
}

/* .sms-edit-container .btn-conatiner-wrapper .btn-container .btn-send {
  background-color: #1b2649;
} */
