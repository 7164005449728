.modal-user-edit-container {
    padding: 20px 40px;
    display: flex;
}

.modal-user-edit-container .each-detail {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

.modal-user-edit-container .each-detail .title {
    width: 110px;
    text-align: left;
}
