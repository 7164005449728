.modal-complete-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.modal-complete-title > h2 {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

.modal-complete-container .result-container {
  background-color: #f0f0f0;
  border: 1px solid lightgray;
  padding: 20px;
  line-height: 35px;
}

.modal-complete-container .result-container .each-result {
  display: flex;
}

.modal-complete-container .result-container .each-result .title {
  width: 100px;
}

.modal-complete-container .btn-container {
  display: flex;
  width: 100%;
  justify-content: end;
}

.modal-complete-container .btn-container > button {
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #1b2649;
  color: white;
  margin-left: 20px;
}

.modal-complete-container .btn-container .close {
  margin-left: 10px;
}

.modal-complete-container .sms-conatiner {
  display: flex;
  align-items: center;
  margin: 20px 0px 40px 0px;
}

.modal-complete-container .sms-conatiner .btn-send {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #1b2649;
  color: white;
  margin-left: 20px;
  font-weight: bold;
}
