/* Department Management Section */
.department-container {
  width: 100%;
  height: 100%;
  padding: 50px;
  overflow: hidden;
}


.department-container .header-btn .btn-department {
  color: white;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 30px;
  cursor: pointer;
}

.department-container .content {
  display: flex;
  width: 100%;
  height: 90%;
}

.department-container .content .left {
  width: 20%
}


.department-container .content .right {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.department-container .search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  height: 40px;
}

.department-container .search-container .left-side {
  height: 100%;
  display: flex;
}

.department-container .search-container .select {
  font-size: 14px;
  color: white;
  border-radius: 5px;
  background-color: var(--gray-04);
  width: 120px;
  height: 100%;
  margin-right: 10px;
  padding: 0 10px;
  border: none;
}

.department-container .search-container .select.event-access {
  width: 260px;
}

.department-container .search-container .select::placeholder {
  color: white;
  font-size: 14px;
}

.department-container .search-container .search {
  padding: 0 10px;
  border-radius: 5px;
  color: white;
  background-color: var(--gray-04);
  width: 300px;
  height: 100%;
  border: none;
}

.department-container .search-container .search::placeholder {
  color: #8088a0;
}

.department-container .search-container .btn-regi {
  background-color: gray;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  color: #496ded;
  font-size: 16px;
  font-weight: bold;
}

.department-container .btn-container {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.department-container .btn-container .MuiPaginationItem-text {
  color: var(--gray-00);
}

.department-container .btn-container .MuiPaginationItem-text.Mui-selected {
  color: white;
}

.department-container .btn-container .left {
  display: flex;
  align-items: center;
}

.department-container .btn-del {
  width: 110px;
  height: 40px;
  border: 1px solid #8088a0;
  border-radius: 5px;
  background-color: #303a59;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.department-container .content .right .bottom {
  color: white;
  font-size: 14px;
}

.department-container .content .right .bottom .info {
  margin-bottom: 4px;
}
