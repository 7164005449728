/* User Detail Modal Section */
.department-detail-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.department-detail-modal-container .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.department-detail-modal-container .header-container .icon {
    margin-bottom: 20px;
}

.department-detail-modal-container .header-container .department {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
}

.department-detail-modal-container .header-container .name-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.department-detail-modal-container .header-container .name {
    font-size: 16px;
}

.department-detail-modal-container .header-container .master.name {
    display: flex;
    align-items: center;
}

.department-detail-modal-container .detail-container {
    margin: 15px 0;
    width: 80%;
}

.department-detail-modal-container .detail-container .each-detail {
    display: flex;
    font-size: 16px;
    padding: 0 10px;
}

.department-detail-modal-container .detail-container .each-detail .title {
    width: 100px;
}

.department-detail-modal-container .detail-container .each-detail .list {
    width: calc(100% - 100px)
}
