.sms-send-detail-container {
  width: 100%;
}

.sms-send-detail-container .header {
  font-size: 22px;
  font-weight: bold;
}

.sms-send-detail-container .each-row {
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  align-items: baseline;
}

.sms-send-detail-container .each-row .title {
  width: 120px;
  font-size: 16px;
}

.sms-send-detail-container .each-row .detail {
  line-height: 25px;
}

.sms-send-detail-container .each-row .detail .main {
  font-size: 18px;
  font-weight: bold;
}

.sms-send-detail-container .each-row .detail .sub {
  font-size: 14px;
  color: #666666;
}

.sms-send-detail-container .each-row .detail .sub.wrapper {
  display: flex;
}

.sms-send-detail-container .each-row .detail .sub.wrapper .left {
  margin-right: 20px;
}

.sms-send-detail-container .each-row .content {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  min-height: 200px;
  max-height: 200px;
}

.sms-send-detail-container .btn-container {
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}

.sms-send-detail-container .btn-container .btn {
  width: 150px;
  height: 45px;
  font-size: 16px;
}

.sms-send-detail-container .btn-container .btn-close {
  background-color: white;
  color: var(--main-ny-sub-black);
  border: 1px solid var(--main-ny-sub-black);
}
