/* Delete Department Modal Section */
.department-delete-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
}

.department-delete-modal-container .header {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 22px;
    text-align: center;
}

.department-delete-modal-container .confirm {
    margin-bottom: 10px;
    text-align: center;
}

.department-delete-modal-container .confirm > strong {
    font-weight: bold;
    font-size: 17px;
    color: #496ded;
}

.department-delete-modal-container .detail {
    background-color: #f4f4f4;
    padding: 0 20px;
    border-radius: 5px;
    color: #6c6c6c;
    margin-bottom: 20px;
    width: 460px;
    height: 120px;
}

.department-delete-modal-container .detail .each-detail {
    display: flex;
    margin: 20px 0;
    font-size: 14px;
    text-align: left;
}

.department-delete-modal-container .detail .each-detail .title {
    width: 110px;
    text-align: left;
    margin-right: 10px;
}

.department-delete-modal-container .detail .each-detail .list {
    width: calc(100% - 110px)
}

/*.department-delete-modal-container .detail .each-detail .title.permission {*/
/*    width: 300px;*/
/*}*/
